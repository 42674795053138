import MDinput from '@/components/MDinput';

export function getFormDefinition(vue) {
  return {
    id: {
      defaultValue: undefined,
      type: null
    },
    code: {
      defaultValue: '',
      props: {
        caption: 'translation.code',
        maxlength: 2,
        required: true
      },
      type: MDinput
    },
    description: {
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true
      },
      type: MDinput
    }
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
